import { baseApi, ROUTE_PLANNER_KEY } from '@/store/baseApi';
import { OrsGetRouteResponse } from '@ekt-group/general-purpose-api-interfaces';
import { GetOrsRouteQuery } from './route-planner.service';

const enhancedApi = baseApi.enhanceEndpoints({
  addTagTypes: [ROUTE_PLANNER_KEY],
});

export const routerPlannerApi = enhancedApi.injectEndpoints({
  endpoints: (builder) => ({
    getOrsRoute: builder.query<Partial<OrsGetRouteResponse>, GetOrsRouteQuery>({
      query: ({ start, end }) => {
        return {
          url: '/external/ors-route',
          params: {
            start: start.join(),
            end: end.join(),
          },
        };
      },
      keepUnusedDataFor: 0,
    }),
  }),
});

export const { useLazyGetOrsRouteQuery } = routerPlannerApi;
