import { FieldErrors } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface ErrorMessage {
  path: string;
  message: string;
}

interface ErrorDisplayProps<T> {
  errors: FieldErrors<T>;
}

export const ErrorDisplay = <T extends Record<string, any>>({ errors }: ErrorDisplayProps<T>) => {
  const { t } = useTranslation();

  if (!errors) {
    return null;
  }

  const getErrorMessages = (errorObj: Record<string, any>, path = ''): ErrorMessage[] => {
    const messages: ErrorMessage[] = [];

    for (const [key, value] of Object.entries(errorObj)) {
      const currentPath = path ? `${path}.${key}` : key;

      if (value?.message && typeof value.message === 'string') {
        messages.push({
          path: currentPath,
          message: t(value.message, { ns: 'validation' }),
        });
      } else if (typeof value === 'object' && value !== null) {
        messages.push(...getErrorMessages(value, currentPath));
      }
    }

    return messages;
  };

  const errorMessages = getErrorMessages(errors);

  if (errorMessages.length === 0) {
    return null;
  }

  return (
    <div className="fixed left-0 right-0 z-10 p-4 mx-4 border border-red-200 rounded-md shadow-md bottom-20 bg-red-50">
      <div className="text-sm text-red-600">
        {errorMessages.map((error, index) => (
          <div key={index} className="mb-1">
            {error.message}
          </div>
        ))}
      </div>
    </div>
  );
};
