import React, { createContext, useContext, ReactNode, memo } from 'react';
import { Waypoint, UnloadingBaseWaypoint } from '@/features/home';
import { WaypointListSortingOption } from '@/features/route';
import { useSortedWaypointGroups } from './useSortedWaypointGroups';

// React.memo(WaypointsList)
//   └── React.memo(SortedWaypointGroupsProvider) [contains geolocation logic]
//       └── WaypointsListInner [only re-renders when sorted waypoints change]
type SortedWaypointGroupsContextType = {
  sortedWaypointGroups: (Waypoint[] | UnloadingBaseWaypoint[])[];
  currentSortingOption: WaypointListSortingOption;
  setSortedWaypointGroups: React.Dispatch<React.SetStateAction<(Waypoint[] | UnloadingBaseWaypoint[])[]>>;
  isFiltersSelected: boolean;
} | null;

const SortedWaypointGroupsContext = createContext<SortedWaypointGroupsContextType>(null);

type SortedWaypointGroupsProviderProps = {
  children: ReactNode;
  waypointGroups: Waypoint[][];
  onWaypointGroupsChange: (waypointGroups: (Waypoint[] | UnloadingBaseWaypoint[])[]) => void;
};

const SortedWaypointGroupsProviderComponent = ({ children, waypointGroups, onWaypointGroupsChange }: SortedWaypointGroupsProviderProps) => {
  const hookResult = useSortedWaypointGroups(waypointGroups, onWaypointGroupsChange);

  return <SortedWaypointGroupsContext.Provider value={hookResult}>{children}</SortedWaypointGroupsContext.Provider>;
};

export const SortedWaypointGroupsProvider = memo(SortedWaypointGroupsProviderComponent);

export const useSortedWaypointGroupsContext = () => {
  const context = useContext(SortedWaypointGroupsContext);
  if (!context) {
    throw new Error('useSortedWaypointGroupsContext must be used within a SortedWaypointGroupsProvider');
  }
  return context;
};
