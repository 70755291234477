import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';

interface UseUnloadWeightsForm {
  initialWeight: number | null;
  initialGrossWeight: number | null;
  initialNetWeight: number | null;
}

export interface WeightForm {
  weight: number;
  grossWeight: number;
  netWeight: number;
}

export function useUnloadWeightsForm(
  { initialWeight, initialGrossWeight, initialNetWeight }: UseUnloadWeightsForm,
  isAllowGrossNetInput: boolean,
) {
  const { watch, setValue, register } = useForm<WeightForm>({
    values: {
      weight: initialWeight || 0,
      grossWeight: initialGrossWeight || 0,
      netWeight: initialNetWeight || 0,
    },
  });

  const netWeight = watch('netWeight', 0);
  const grossWeight = watch('grossWeight');
  const weight = watch('weight');

  const isInvalidWeight = useCallback(
    (weight: number | string, grossWeight: number | string, netWeight: number | string) => {
      const weightNum = typeof weight === 'string' ? parseFloat(weight) : weight;
      const grossWeightNum = typeof grossWeight === 'string' ? parseFloat(grossWeight) : grossWeight;
      const netWeightNum = typeof netWeight === 'string' ? parseFloat(netWeight) : netWeight;

      if (isNaN(weightNum) || isNaN(grossWeightNum) || isNaN(netWeightNum)) {
        return true;
      }

      if (!isAllowGrossNetInput) {
        return weightNum < 0;
      }
      return netWeightNum > grossWeightNum || netWeightNum < 0 || grossWeightNum < 0;
    },
    [isAllowGrossNetInput],
  );

  return {
    isInvalidWeight,
    register,
    setValue,
    watch,
    netWeight,
    grossWeight,
    weight,
  };
}
