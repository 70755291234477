import React, { useMemo, useRef } from 'react';
import { selectCarLogCoordinates, selectMapSettings, selectPlanningRoutes } from '@/features/route';
import { useTranslation } from 'react-i18next';
import {
  selectCurrentRouteGroupItem,
  selectIsCurrentRouteGroupItemOpenedMode,
  UnloadingBaseWaypoint,
  Waypoint,
  WaypointType,
} from '@/features/home';
import { MapComponent, MapMode } from '@ekt-group/map-component';
import { getBackendHost } from '@/helpers/backend';
import useGeolocation from 'react-hook-geolocation';
import { useAppSelector } from '@/store/types';
import { toast } from 'react-toastify';

interface Props {
  waypoints: (Waypoint | UnloadingBaseWaypoint)[];
  selectedWaypointIds: number[];
  coordinatesToObject: number[][];
  handleWaypointClick: (waypointIds: number[]) => void;
}

const checkIfStatusChange = (waypoint: Waypoint | UnloadingBaseWaypoint) => {
  if (waypoint.type === WaypointType.Discharge) {
    return waypoint.done || waypoint.fail;
  }
  if (waypoint.type === WaypointType.Service) {
    return waypoint.doneAt || waypoint.isFailed;
  }
  return false;
};

let renderCount = 0;

const areEqual = (prev: Props, next: Props) => {
  if (prev.selectedWaypointIds?.length !== next.selectedWaypointIds?.length) {
    toast.info(`Re-render: selectedWaypointIds length changed ${prev.selectedWaypointIds?.length} → ${next.selectedWaypointIds?.length}`);
    return false;
  }
  if (prev.waypoints?.length !== next.waypoints?.length) {
    toast.info(`Re-render: waypoints length changed ${prev.waypoints?.length} → ${next.waypoints?.length}`);
    return false;
  }
  if (prev.coordinatesToObject?.length !== next.coordinatesToObject?.length) {
    toast.info(`Re-render: coordinatesToObject length changed ${prev.coordinatesToObject?.length} → ${next.coordinatesToObject?.length}`);
    return false;
  }

  const prevLatittudes = prev.waypoints?.map(
    (waypoint) => (waypoint as Waypoint<WaypointType.Discharge> | Waypoint<WaypointType.Service>).objectItem?.latitude,
  );
  const nextLatittudes = next.waypoints?.map(
    (waypoint) => (waypoint as Waypoint<WaypointType.Discharge> | Waypoint<WaypointType.Service>).objectItem?.latitude,
  );

  const latittudesMatched = prevLatittudes?.every((latittude, index) => latittude === nextLatittudes[index]);
  if (!latittudesMatched) {
    toast.info('Re-render: waypoint latitudes changed');
    return false;
  }

  const prevWaypointIdsMatched = prev.selectedWaypointIds?.every((id) => next.selectedWaypointIds.includes(id));
  const nextWaypointIdsMatched = next.selectedWaypointIds?.every((id) => prev.selectedWaypointIds.includes(id));
  if (!prevWaypointIdsMatched || !nextWaypointIdsMatched) {
    toast.info('Re-render: selectedWaypointIds content changed');
    return false;
  }

  const coordinatesToObjectMatched = prev.coordinatesToObject?.every((coordinate) => next.coordinatesToObject?.includes(coordinate));
  if (!coordinatesToObjectMatched) {
    toast.info('Re-render: coordinatesToObject content changed');
    return false;
  }

  const prevDoneLength = prev.waypoints?.filter(checkIfStatusChange).length;
  const nextDoneLength = next.waypoints?.filter(checkIfStatusChange).length;
  const doneMatched = prevDoneLength === nextDoneLength;
  if (!doneMatched) {
    toast.info(`Re-render: waypoints done status changed ${prevDoneLength} → ${nextDoneLength}`);
    return false;
  }

  return true;
};

const WaypointsMapListWrapper = ({ waypoints, selectedWaypointIds, coordinatesToObject, handleWaypointClick }: Props) => {
  renderCount++;

  // Render count tracking
  if (renderCount % 50 === 0) {
    toast.info(`Map component rendered ${renderCount} times`);
  }

  const { i18n } = useTranslation('photoPage');
  const { error } = useGeolocation();
  const currentRouteGroupItem = useAppSelector(selectCurrentRouteGroupItem);
  const isRouteGroupItemInOpenedMode = useAppSelector(selectIsCurrentRouteGroupItemOpenedMode);
  const mapSettings = useAppSelector(selectMapSettings);
  const planningObject = useAppSelector(selectPlanningRoutes);
  const carLogCoordinates = useAppSelector(selectCarLogCoordinates);

  // waypoints without "unloading" and "base" type
  const waypointsFiltered = useMemo(
    () => waypoints.filter((waypoint) => waypoint.type !== 'unloading' && waypoint.type !== 'base') as Waypoint[],
    [waypoints],
  );

  return (
    <div className="tablet-page__map">
      {currentRouteGroupItem && (
        <MapComponent
          mode={MapMode.DriverTablet}
          tilesMode={mapSettings.tilesMode}
          generalPurposesHostUrl={getBackendHost()}
          routeGroupItem={currentRouteGroupItem}
          waypoints={waypointsFiltered}
          selectedWaypointIds={selectedWaypointIds}
          coordinatesToObject={coordinatesToObject}
          onObjectClick={handleWaypointClick}
          settings={{
            panOnWaypoint: mapSettings.panOnWaypointChange,
            stickToCar: isRouteGroupItemInOpenedMode ? false : mapSettings.stickToCar,
            showNavigationLine: mapSettings.showNavigationLine,
            autoZoom: mapSettings.autoZoom,
            showDoneWaypoints: mapSettings.showDoneWaypoints,
            enableRotation: mapSettings.enableRotation,
            isGeolocationEnabled: !error,
          }}
          locale={i18n.resolvedLanguage}
          planningObject={planningObject}
          carLogCoordinates={carLogCoordinates}
        />
      )}
    </div>
  );
};

export default React.memo(WaypointsMapListWrapper, areEqual);
