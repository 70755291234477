import { z } from 'zod';

export const checkUpFormSchema = z.object({
  driverId: z.number(),
  carId: z.string(),
  picturesForm: z.object({
    files: z
      .array(
        z.object({
          file: z.instanceof(File),
          position: z.number(),
        }),
      )
      .refine((files) => files?.length === 4, {
        message: 'validation.allPhotosRequired',
      }),
  }),
  carProblemForm: z.object({
    serviceName: z.string().min(1, { message: 'validation.serviceNameRequired' }),
    description: z.string().optional(),
    photos: z.array(z.instanceof(File)).optional(),
    priority: z.number(),
  }),
});

export type CheckUpFormSchema = z.infer<typeof checkUpFormSchema>;
