import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectSelectedUnloadPoint, setSelectedUnloadPoint } from '@/features/unloading';
import { UnloadPoint, WaypointUnload, WaypointUnloadCreateDto } from '@ekt-group/general-purpose-api-interfaces';
import { Coordinates } from '@/types/common';
import { getDistanceInMeters } from '@/helpers/coordinates';
import UnloadPointsListItemUnload from './UnloadPointsListItemUnload';
import { useNavigate } from 'react-router-dom';
import Spinner from '@/components/elements/Spinner';
import { useOfflineStatus } from '@/contexts/offlineStatusContext';
import { useLiveQuery } from 'dexie-react-hooks';
import { UnloadRequestType, unloadsRequestsDb } from '@/database';
import { useTranslation } from 'react-i18next';
import { conditionalClassNames } from '@/helpers/dom';
import { selectCurrentRouteGroupItem } from '@/features/home';
import useGeolocation from 'react-hook-geolocation';
import { useUnloads } from '../hooks/useUnloads';

interface UnloadPointsListItemProps {
  point: UnloadPoint;
  onPointClick: (point: UnloadPoint) => void;
}

const UnloadPointsListItem = ({ point, onPointClick }: UnloadPointsListItemProps) => {
  const { t } = useTranslation('unloadingPage');
  const carPosition = useGeolocation();
  const pendingUnloadRequests = useLiveQuery(() => unloadsRequestsDb.requests.toArray());
  const selectedUnloadPoint = useSelector(selectSelectedUnloadPoint);
  const currentRouteGroupItem = useSelector(selectCurrentRouteGroupItem);

  const { waypointUnloads, isWaypointUnloadsLoading } = useUnloads(currentRouteGroupItem?.id);

  const filteredWaypointUnloads = waypointUnloads.filter((unload) => unload.unloadPointId === point.id);

  const getDistanceToUnloadPoint = ({ longitude, latitude }: Coordinates) => {
    return Intl.NumberFormat('et-EE', { maximumFractionDigits: 2 }).format(
      getDistanceInMeters({ longitude: carPosition.longitude, latitude: carPosition.latitude }, { longitude, latitude }) / 1000,
    );
  };

  const pendingUnloadsInIndexedDB = pendingUnloadRequests
    ? pendingUnloadRequests
        .filter(
          (request) => request.type === UnloadRequestType.Create && (request.body as WaypointUnloadCreateDto)?.unloadPointId === point.id,
        )
        .map(({ body, id }) => ({ ...body, id, isPending: true }))
    : [];

  const pendingUnloadRequestExists = pendingUnloadRequests
    ? pendingUnloadRequests.some(
        (request) =>
          request.type === UnloadRequestType.Create &&
          (request.body as WaypointUnloadCreateDto)?.unloadPointId === point.id &&
          request.isEditing,
      )
    : false;

  const allUnloads: Partial<WaypointUnload>[] = [...filteredWaypointUnloads, ...pendingUnloadsInIndexedDB];

  return (
    <div className="unloading-page__list-item" key={point.id}>
      <button
        className={conditionalClassNames({
          'unloading-page__list-item__point': true,
          active: selectedUnloadPoint?.id === point.id || pendingUnloadRequestExists,
        })}
        onClick={() => onPointClick(point)}
      >
        <div className="unloading-page__list-item__point__title">
          <span className="unloading-page__list-item__name">{point.name}</span>
          <span className="unloading-page__list-item__distance">
            {getDistanceToUnloadPoint({ latitude: point.latitude, longitude: point.longitude })} km
          </span>
        </div>
        <div>
          <span className="unloading-page__list-item__action">{t('clickForWeightEnter')}</span>
        </div>
      </button>
      <div className="unloading-page__list-item__unloads">
        <span className="unloading-page__list-item__unloads-title">{t('weight')}</span>
        <div className="unloading-page__list-item__unloads-list">
          {isWaypointUnloadsLoading ? (
            <Spinner size="md" />
          ) : allUnloads.length > 0 ? (
            allUnloads.map((unload) => (
              <UnloadPointsListItemUnload unload={unload} allowGrossNetInput={point.allowGrossNetInput} key={unload.id} />
            ))
          ) : (
            <p>{t('noUnloads')}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default UnloadPointsListItem;
