import {
  Message,
  messagesApi,
  selectNewMessages,
  useGetMessagesHistoryQuery,
  useGetNewMessagesQuery,
  useMarkMessageAsReadMutation,
} from '@/features/messages';
import { useSelector } from 'react-redux';
import { selectUserId } from '@/features/auth';
import { useUserProfession } from '@/features/home';
import { useOfflineStatus } from '@/contexts/offlineStatusContext';
import { useEffect, useMemo } from 'react';
import { MessagesRequest, messagesRequestsDb } from '@/database/messages-requests.db';
import { useAppDispatch, useAppSelector } from '@/store/types';
import { useSocketStatus } from '@/contexts/socketContext';
import { getDaysAgoDate } from '@/helpers/dateHelper';

const REQUEST_INTERVAL = 120000;
const MESSAGE_DISPLAY_DAYS = 15;

// Filter out messages older than MESSAGE_DISPLAY_DAYS
const filterMessagesByAge = <T extends { createdAt: string }>(messages: T[]): T[] => {
  if (!messages || messages.length === 0) {
    return [];
  }

  const thresholdDate = getDaysAgoDate(MESSAGE_DISPLAY_DAYS);
  return messages.filter((message) => message.createdAt.split('T')[0] >= thresholdDate);
};

interface UseMessagesProps {
  onNewMessage?: (message: Message) => void;
}

// this hook assumes userId is available in store
export function useMessages({ onNewMessage }: UseMessagesProps = { onNewMessage: null }) {
  const userId = useSelector(selectUserId);
  const isOffline = useOfflineStatus();
  const isSocketConnected = useSocketStatus();
  const { isUserLoader } = useUserProfession();
  const dispatch = useAppDispatch();

  // cache for this endpoint is kept for infinity
  const { data: oldMessages, isLoading: isOldMessagesLoading } = useGetMessagesHistoryQuery(userId, {
    skip: !userId,
  });

  // directly select cache since "data" from useQuery doesn't work
  const { data: newMessages } = useAppSelector(selectNewMessages(userId));
  const { isLoading: isNewMessagesLoading } = useGetNewMessagesQuery(userId, {
    skip: !userId || isOffline || isUserLoader() || isSocketConnected,
    pollingInterval: REQUEST_INTERVAL,
  });

  const [markAsRead] = useMarkMessageAsReadMutation();

  const markMessageAsRead = async (messageId: number) => {
    const payload: MessagesRequest = {
      messageId: messageId,
      timestamp: new Date().toISOString(),
    };

    await markAsRead(payload)
      .unwrap()
      .catch((error) => {
        messagesRequestsDb.requests.add(payload);
      });
  };

  useEffect(() => {
    dispatch(messagesApi.endpoints.getNewMessages.initiate(userId));
  }, [dispatch, userId]);

  // listening to the cache and callback whenever socket or polling updates the cache
  useEffect(() => {
    if (!newMessages || newMessages?.length === 0) {
      return;
    }

    const filteredNewMessages = filterMessagesByAge(newMessages);

    if (filteredNewMessages.length === 0) {
      return;
    }

    const lastNewMessage = filteredNewMessages.find((message) => !message.readAt);
    if (lastNewMessage) {
      onNewMessage && onNewMessage(lastNewMessage);
    }
  }, [newMessages, onNewMessage]);

  const sortedMessages = useMemo(() => {
    if (!oldMessages) {
      return [];
    }

    return filterMessagesByAge(oldMessages).sort((a, b) => {
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    });
  }, [oldMessages]);

  const getFilteredNewMessagesCount = useMemo(() => {
    if (!newMessages || newMessages.length === 0) {
      return 0;
    }

    return filterMessagesByAge(newMessages).length;
  }, [newMessages]);

  return {
    messages: sortedMessages,
    isOldMessagesLoading,
    isNewMessagesLoading,
    newMessagesCount: getFilteredNewMessagesCount,
    markMessageAsRead,
  };
}
