import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

const languages = ['en', 'et', 'ru'];

const namespaces = [
  'alcometer',
  'arrivedPage',
  'articleCodes',
  'cameraWrapper',
  'common',
  'errors',
  'fuelPage',
  'header',
  'kojvConditions',
  'loginPage',
  'mainPage',
  'messages',
  'offline',
  'photoPage',
  'routeGroupItems',
  'services',
  'tabletPage',
  'unloadingPage',
  'carCheckUpModal',
  'filterModal',
  'cacheModal',
  'validation',
];

const loadTranslations = (lang: string) => {
  fetch(`/locales/${lang}.json`)
    .then((response) => response.json())
    .then((data) => {
      namespaces.forEach((namespace) => {
        i18n.addResourceBundle(lang, namespace, data[namespace], true, false);
      });
    })
    .catch((error) => {
      console.error(`Failed to load translations for ${lang}`, error);
    });
};

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: 'et',
    returnNull: false,
    ns: namespaces,
    defaultNS: 'common',
    fallbackNS: 'common',
    fallbackLng: 'et',
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: '/locales/{{lng}}.json',
    },
  });

languages.forEach((lang) => {
  loadTranslations(lang);
});

export default i18n;
