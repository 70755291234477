import { CompletedRouteOrderItem, IWaypointBase, IWaypointDischarge, UnloadingBaseWaypoint, Waypoint, WaypointType } from '@/features/home';
import { getObjectAddress } from '@/helpers/strings';
import { useCommonItemMaps } from '@/hooks/useCommonItemMaps';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectDisplayArrivedPage,
  selectHistoricalSortingRouteGroupItemId,
  selectSelectedPlanningItem,
  selectSelectedWaypointListSortingOption,
  setSelectedWaypointListSortingOption,
  WaypointListFormData,
  WaypointListSortingOption,
} from '@/features/route';
import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { getDistanceInMeters } from '@/helpers/coordinates';
import { useLiveQuery } from 'dexie-react-hooks';
import { toast } from 'react-toastify';
import { historicalCompletedOrdersDb } from '@/database/historical-completed-orders.db';
import { selectFilters, selectSelectedWaypointIds, setFilteredWaypoints, setSelectedWaypointIds } from '@/store/waypoints/slice';
import { isEmptyObject } from '@/utils/isEmptyObject';
import { formatKojvTimeSlot } from '../../helpers/formatKojvTimeSlot';
import { RouteGroupItemPlanning, RouteGroupItemPlanningService } from '@ekt-group/general-purpose-api-interfaces';
import { isEqual } from 'lodash';
import { useGeolocated } from 'react-geolocated';

type RoutePlan = RouteGroupItemPlanning<RouteGroupItemPlanningService.Vroom>['planning']['response']['routes'];

const MIN_DISTANCE_THRESHOLD = 5;

export function useSortedWaypointGroups(
  waypointGroups: Waypoint[][],
  onWaypointGroupsChange: (waypointGroups: (Waypoint[] | UnloadingBaseWaypoint[])[]) => void,
) {
  const [sortedWaypointGroups, setSortedWaypointGroups] = useState<(Waypoint[] | UnloadingBaseWaypoint[])[]>([]);
  const historicalOrders = useLiveQuery(() => historicalCompletedOrdersDb.orders.toArray());

  const { citiesMap, villagesMap, countiesMap } = useCommonItemMaps();
  const dispatch = useDispatch();

  const sortingOption = useSelector(selectSelectedWaypointListSortingOption);
  const historicalSortingRouteGroupItemId = useSelector(selectHistoricalSortingRouteGroupItemId);
  const planningItem = useSelector(selectSelectedPlanningItem);

  const selectedWaypointIds = useSelector(selectSelectedWaypointIds);
  const isDisplayArrivedPage = useSelector(selectDisplayArrivedPage);

  const filters = useSelector(selectFilters);

  const shouldTrackPosition = sortingOption === WaypointListSortingOption.Closest;

  const geoOptions = useMemo(
    () => ({
      positionOptions: {
        enableHighAccuracy: true,
        maximumAge: 0,
        timeout: 10000,
      },
      watchPosition: shouldTrackPosition,
      suppressLocationOnMount: !shouldTrackPosition,
      userDecisionTimeout: 5000,
    }),
    [shouldTrackPosition],
  );

  const { coords } = useGeolocated(geoOptions);

  const prevPositionRef = useRef({ latitude: 0, longitude: 0 });
  const debouncedPositionRef = useRef({ latitude: 0, longitude: 0 });
  const lastSortTimeRef = useRef(0);

  const currentPosition = useMemo(() => {
    if (!shouldTrackPosition) {
      return { latitude: 0, longitude: 0 };
    }

    const now = Date.now();

    const rawLat = coords?.latitude;
    const rawLong = coords?.longitude;

    if (rawLat != null && rawLong != null) {
      prevPositionRef.current = { latitude: rawLat, longitude: rawLong };
    }

    // Only update the debounced position that's used for sorting if:
    // 1. We have valid coordinates
    // 2. Either this is the first location update or we've exceeded the minimum distance threshold
    // 3. We haven't sorted too recently (at least 2 seconds between sorts)
    if (
      rawLat != null &&
      rawLong != null &&
      (debouncedPositionRef.current.latitude === 0 ||
        (getDistanceInMeters(
          { latitude: debouncedPositionRef.current.latitude, longitude: debouncedPositionRef.current.longitude },
          { latitude: rawLat, longitude: rawLong },
        ) > MIN_DISTANCE_THRESHOLD &&
          now - lastSortTimeRef.current > 2000))
    ) {
      const distance =
        debouncedPositionRef.current.latitude !== 0
          ? getDistanceInMeters(
              { latitude: debouncedPositionRef.current.latitude, longitude: debouncedPositionRef.current.longitude },
              { latitude: rawLat, longitude: rawLong },
            )
          : 0;

      if (distance > MIN_DISTANCE_THRESHOLD) {
        toast.info(`Position changed significantly (${distance.toFixed(0)}m), updating sort`);
        debouncedPositionRef.current = { latitude: rawLat, longitude: rawLong };
        lastSortTimeRef.current = now;
      }
    }

    return debouncedPositionRef.current;
  }, [coords, shouldTrackPosition]);

  const isFiltersSelected = useMemo(() => {
    if (!filters) {
      return false;
    }

    return !isEmptyObject(filters);
  }, [filters]);

  const getAddressByWaypoint = useCallback(
    (waypoint: IWaypointBase) => {
      const { object } = waypoint;
      const { cityId, villageId, countyId } = object;

      return getObjectAddress(
        {
          object,
          city: citiesMap[cityId],
          village: villagesMap[villageId],
          county: countiesMap[countyId],
        },
        ['county'],
      );
    },
    [citiesMap, villagesMap, countiesMap],
  );

  const closestObjectSorting = useCallback((a: Waypoint[], b: Waypoint[], objectDistanceMap: Record<string, number>) => {
    const aDone = a.every((waypoint) => (waypoint.type === WaypointType.Discharge ? waypoint.done : waypoint.doneAt));
    const bDone = b.every((waypoint) => (waypoint.type === WaypointType.Discharge ? waypoint.done : waypoint.doneAt));

    if (aDone && bDone) {
      return objectDistanceMap[a[0].object.id] - objectDistanceMap[b[0].object.id];
    }
    if (aDone) {
      return 1;
    }
    if (bDone) {
      return -1;
    }

    return objectDistanceMap[a[0].object.id] - objectDistanceMap[b[0].object.id];
  }, []);

  const alphabeticalSorting = useCallback(
    (a: Waypoint[], b: Waypoint[]) => {
      const aAddress = getAddressByWaypoint(a[0]);
      const bAddress = getAddressByWaypoint(b[0]);

      const aDone = a.some((waypoint) => (waypoint.type === WaypointType.Discharge ? waypoint.done : waypoint.doneAt));
      const bDone = b.some((waypoint) => (waypoint.type === WaypointType.Discharge ? waypoint.done : waypoint.doneAt));

      if (aDone && bDone) {
        return aAddress.localeCompare(bAddress);
      }
      if (aDone) {
        return 1;
      }
      if (bDone) {
        return -1;
      }

      return aAddress.localeCompare(bAddress);
    },
    [getAddressByWaypoint],
  );

  const historicalOrderSorting = (order: CompletedRouteOrderItem[]) => (a: Waypoint[], b: Waypoint[]) => {
    const aObjectId = a[0].object.id;
    const bObjectId = b[0].object.id;

    const aIndexOf = order.findIndex(({ id }) => id === aObjectId);
    const bIndexOf = order.findIndex(({ id }) => id === bObjectId);

    const aDone = a.every((waypoint) => (waypoint.type === WaypointType.Discharge ? waypoint.done : waypoint.doneAt));
    const bDone = b.every((waypoint) => (waypoint.type === WaypointType.Discharge ? waypoint.done : waypoint.doneAt));

    const sortByIndexOf = () => {
      if (aIndexOf !== -1 && bIndexOf !== -1) {
        return aIndexOf - bIndexOf;
      }
      if (aIndexOf !== -1) {
        return -1;
      }
      if (bIndexOf !== -1) {
        return 1;
      }

      return 0;
    };

    if (aDone && bDone) {
      return sortByIndexOf();
    }
    if (aDone) {
      return 1;
    }
    if (bDone) {
      return -1;
    }

    return sortByIndexOf();
  };

  const optimizedSorting = useCallback((waypointsGroup: Waypoint[][], routePlans: RoutePlan) => {
    const flatWaypoints = waypointsGroup.flat();
    const firstStep = routePlans[0].steps[0];

    const sortedWaypointsGroup: (Waypoint | UnloadingBaseWaypoint)[][] = [];

    const routePlansWithoutLastItem = routePlans.slice(0, routePlans.length - 1);

    for (const route of routePlansWithoutLastItem) {
      const routeSteps = route.steps.slice();

      for (const step of routeSteps) {
        if (step.type === 'end') {
          const unloadingItem: UnloadingBaseWaypoint[] = [
            {
              type: 'unloading',
              coordinates: {
                longitude: step.location[0],
                latitude: step.location[1],
              },
              isFiltered: true,
            },
          ];
          sortedWaypointsGroup.push(unloadingItem);
          continue;
        }

        const waypointGroup = flatWaypoints.filter((waypoint) => waypoint.object.id === step.job);
        if (waypointGroup?.length > 0) {
          sortedWaypointsGroup.push(waypointGroup);
          continue;
        }
      }
    }
    const baseItem: UnloadingBaseWaypoint[] = [
      {
        type: 'base',
        coordinates: {
          longitude: firstStep.location[0],
          latitude: firstStep.location[1],
        },
        isFiltered: true,
      },
    ];

    sortedWaypointsGroup.push(baseItem);

    return sortedWaypointsGroup;
  }, []);

  const sortingFunctionsMap = useMemo(
    () => ({
      [WaypointListSortingOption.Alphabetical]: alphabeticalSorting,
      [WaypointListSortingOption.Closest]: closestObjectSorting,
      [WaypointListSortingOption.HistoricalOrder]: historicalOrderSorting,
      [WaypointListSortingOption.Optimized]: optimizedSorting,
    }),
    [alphabeticalSorting, closestObjectSorting, optimizedSorting],
  );

  const filter = (waypointGroups: (Waypoint[] | UnloadingBaseWaypoint[])[], filters: WaypointListFormData) => {
    const isExistItemTypesFilter = filters.itemTypes.length > 0;
    const isExistGarbagesFilter = filters.garbages.length > 0;
    const isExistHasKeyFilter = filters.hasKey !== null && filters.hasKey !== undefined;
    const isExistHasMobileGateFilter = filters.hasMobileGate !== null && filters.hasMobileGate !== undefined;
    const isExistSpecialServiceTimeFilter = filters.specialServiceTime !== null && filters.specialServiceTime !== undefined;
    const isExistKojvFilter = filters.kojv.length > 0;
    const isExistServicesFilter = filters.services.length > 0;
    const isExistKojvTimeSlotFilter = filters.kojvTimeSlots.length > 0;

    const filteredSortedWaypointGroups = waypointGroups.map((waypointGroup) => {
      return waypointGroup.map((waypoint) => {
        const disChargeWaypoint = waypoint as IWaypointDischarge;

        // waypoint can be unloadingbase type, which doesn't have objectItem
        if (!disChargeWaypoint?.objectItem) {
          return waypoint;
        }

        // checkboxes
        let isItemTypeMatched = true,
          isGarbageMatched = true,
          isKojvMatched = true,
          isKojvTimeSlotMatched = true,
          isServiceMatched = true;

        // switches
        let isHasKeyMatched = true,
          isHasMobileGateMatched = true,
          isSpecialServiceTimeMatched = true;

        if (isExistItemTypesFilter) {
          const itemTypeIds = filters.itemTypes.map((itemType) => itemType.id);
          isItemTypeMatched = itemTypeIds.includes(disChargeWaypoint.objectItem.itemTypeId);
        }

        if (isExistGarbagesFilter) {
          const garbageIds = filters.garbages.map((garbage) => garbage.id);
          isGarbageMatched = garbageIds.includes(disChargeWaypoint.objectItem?.garbageId);
        }

        if (isExistHasKeyFilter) {
          isHasKeyMatched = disChargeWaypoint.object?.hasLock === filters.hasKey;
        }

        if (isExistHasMobileGateFilter) {
          const isWaypointHasPhone =
            !!disChargeWaypoint.object?.gateClosePhone || !!disChargeWaypoint.object?.gatePhone || !!disChargeWaypoint.object?.gatePhone2;
          isHasMobileGateMatched = isWaypointHasPhone === filters.hasMobileGate;
        }

        if (isExistSpecialServiceTimeFilter) {
          if (filters.specialServiceTime) {
            isSpecialServiceTimeMatched = !!(disChargeWaypoint.object?.serviceStartTime || disChargeWaypoint.object?.serviceEndTime);
          } else {
            isSpecialServiceTimeMatched = !(disChargeWaypoint.object?.serviceStartTime || disChargeWaypoint.object?.serviceEndTime);
          }
        }

        if (isExistKojvFilter) {
          const kojvIds = filters.kojv.map((kojv) => kojv.id);
          isKojvMatched = kojvIds.includes(disChargeWaypoint.kojv.id);
        }

        if (isExistServicesFilter) {
          const filterServiceIds = filters.services.map((service) => service.id);
          const serviceIds = disChargeWaypoint.services.map((service) => service.id);
          isServiceMatched = serviceIds.some((serviceId) => filterServiceIds.includes(serviceId));
        }

        if (isExistKojvTimeSlotFilter) {
          const kojvTimeSlotStart = disChargeWaypoint.kojv.conditions?.allowedSlotsAt;
          const kojvTimeSlotEnd = disChargeWaypoint.kojv.conditions?.allowedSlotsTo;
          const combinedKojvTimeSlot = `${formatKojvTimeSlot(kojvTimeSlotStart || '')}-${formatKojvTimeSlot(kojvTimeSlotEnd || '')}`;

          isKojvTimeSlotMatched = filters.kojvTimeSlots.includes(combinedKojvTimeSlot);
        }

        if (
          isItemTypeMatched &&
          isGarbageMatched &&
          isKojvMatched &&
          isServiceMatched &&
          isHasKeyMatched &&
          isSpecialServiceTimeMatched &&
          isKojvTimeSlotMatched &&
          isHasMobileGateMatched
        ) {
          return { ...waypoint, isFiltered: true };
        }

        return { ...waypoint, isFiltered: false };
      });
    });

    // bump waypointGroups that has one waypoint with filtered=true to the top
    const bumped = filteredSortedWaypointGroups.reduce<(Waypoint[] | UnloadingBaseWaypoint[])[]>((acc, waypointGroup) => {
      if (waypointGroup[0].type === 'unloading' || waypointGroup[0].type === 'base') {
        return acc;
      }

      const hasFilteredWaypoint = waypointGroup.some((waypoint) => waypoint.isFiltered);

      if (hasFilteredWaypoint) {
        acc.unshift(waypointGroup as Waypoint[]);
      } else {
        acc.push(waypointGroup as Waypoint[]);
      }

      return acc;
    }, []);

    const indexOfLastFiltered = bumped.findLastIndex((waypointGroup: Waypoint[] | UnloadingBaseWaypoint[]) => waypointGroup[0].isFiltered);
    const unloading = filteredSortedWaypointGroups.find(
      (waypointGroup) => waypointGroup[0].type === 'unloading',
    ) as UnloadingBaseWaypoint[];
    const base = filteredSortedWaypointGroups.find((waypointGroup) => waypointGroup[0].type === 'base') as UnloadingBaseWaypoint[];

    if (unloading && base) {
      bumped.splice(indexOfLastFiltered + 1, 0, unloading);
      bumped.splice(indexOfLastFiltered + 2, 0, base);
    }

    return bumped;
  };

  const resetWaypointGroups = useCallback(
    (waypointGroups: (Waypoint[] | UnloadingBaseWaypoint[])[]) => {
      if (!waypointGroups?.length) {
        toast.info('🔍 [useSortedWaypointGroups] setSortedWaypointGroups called from resetWaypointGroups - empty groups');
        setSortedWaypointGroups([]);
        dispatch(setFilteredWaypoints([]));
        return;
      }

      setSortedWaypointGroups((prev) => {
        // we have to compare the whole lists because we have to check for status change as well (done, failed)
        if (isEqual(waypointGroups, prev)) {
          toast.info('🔍 [useSortedWaypointGroups] resetWaypointGroups - no changes detected');
          return prev;
        }

        toast.info(
          `🔍 [useSortedWaypointGroups] setSortedWaypointGroups called from resetWaypointGroups - updating with ${waypointGroups.length} groups`,
        );
        onWaypointGroupsChange(waypointGroups);
        dispatch(setFilteredWaypoints(waypointGroups.flat()));
        return waypointGroups;
      });
    },
    [setSortedWaypointGroups, onWaypointGroupsChange, dispatch],
  );

  const sortWaypointGroups = useCallback(
    async (sortingOption: WaypointListSortingOption, waypointGroups: (Waypoint[] | UnloadingBaseWaypoint[])[]) => {
      toast.info(`sortWaypointGroups running with option: ${sortingOption}`);
      const dischargeOrServiceWaypointsGroups = [...waypointGroups] as Waypoint[][];

      if (sortingOption === WaypointListSortingOption.Closest) {
        const map: Record<string, number> = {};

        dischargeOrServiceWaypointsGroups.forEach((group) => {
          const { object } = group[0];
          const waypointWithCoordinates = group.find(({ objectItem }) => objectItem.longitude && objectItem.latitude);

          const longitude = waypointWithCoordinates?.objectItem.longitude || object.longitude;
          const latitude = waypointWithCoordinates?.objectItem.latitude || object.latitude;

          if (currentPosition.latitude && currentPosition.longitude) {
            map[object.id] = getDistanceInMeters(
              {
                latitude: currentPosition.latitude,
                longitude: currentPosition.longitude,
              },
              { longitude, latitude },
            );
          } else {
            map[object.id] = 0;
          }
        });

        return dischargeOrServiceWaypointsGroups.sort((a, b) => sortingFunctionsMap[WaypointListSortingOption.Closest](a, b, map));
      }

      if (sortingOption === WaypointListSortingOption.HistoricalOrder) {
        const order = historicalOrders?.find(({ routeGroupItemId }) => routeGroupItemId === historicalSortingRouteGroupItemId);
        if (historicalOrders && !order) {
          dispatch(setSelectedWaypointListSortingOption(WaypointListSortingOption.Closest));
          return;
        }
        if (order) {
          const sorted = dischargeOrServiceWaypointsGroups.sort(sortingFunctionsMap[sortingOption](order?.order));
          return sorted;
        }
        return waypointGroups;
      }

      if (sortingOption === WaypointListSortingOption.Optimized) {
        if (!planningItem) {
          return;
        }

        const sorted = sortingFunctionsMap[sortingOption](dischargeOrServiceWaypointsGroups, planningItem.planning.response.routes);
        return sorted;
      }

      const sorted = dischargeOrServiceWaypointsGroups.sort(sortingFunctionsMap[WaypointListSortingOption.Alphabetical]);
      return sorted;
    },
    [
      currentPosition.latitude,
      currentPosition.longitude,
      dispatch,
      historicalOrders,
      historicalSortingRouteGroupItemId,
      planningItem,
      sortingFunctionsMap,
    ],
  );

  const sortAndFilter = useCallback(async () => {
    const sorted = await sortWaypointGroups(sortingOption, waypointGroups);
    const filtered = isFiltersSelected ? filter(sorted as (Waypoint[] | UnloadingBaseWaypoint[])[], filters) : sorted;

    toast.info('🔍 [useSortedWaypointGroups] Calling resetWaypointGroups from sortAndFilter');
    resetWaypointGroups((filtered as Waypoint[][]) || []);

    if (!selectedWaypointIds?.length) {
      toast.info('🔍 [useSortedWaypointGroups] sortAndFilter - setting initial selected waypoint IDs');
      dispatch(setSelectedWaypointIds(filtered?.[0]?.map(({ id }) => id) || []));
      return;
    }

    dispatch(setSelectedWaypointIds(selectedWaypointIds));
  }, [sortWaypointGroups, sortingOption, waypointGroups, isFiltersSelected, filters, resetWaypointGroups, selectedWaypointIds, dispatch]);

  // !! This also run everytime a status of a waypoint changes
  useEffect(() => {
    if (isDisplayArrivedPage) {
      toast.info('🔍 [useSortedWaypointGroups] Main useEffect - arrived page displayed, skipping sort');
      return;
    }

    if (!waypointGroups?.length) {
      toast.info('🔍 [useSortedWaypointGroups] Calling resetWaypointGroups from main useEffect - empty waypoint groups');
      dispatch(setSelectedWaypointIds([]));
      resetWaypointGroups([]);
      return;
    }

    toast.info(
      `🔍 [useSortedWaypointGroups] Main useEffect triggered. Deps: waypointGroups=${waypointGroups.length}, sortingOption=${sortingOption}`,
    );
    toast.info('🔍 [useSortedWaypointGroups] Calling sortAndFilter from main useEffect');
    sortAndFilter();
  }, [dispatch, filters, isDisplayArrivedPage, isFiltersSelected, resetWaypointGroups, sortingOption, waypointGroups]);

  useEffect(() => {
    toast.info('🔍 [useSortedWaypointGroups] useEffect - sortingOption changed');
    if (sortingOption !== WaypointListSortingOption.Optimized) {
      localStorage.setItem('sortingOption', sortingOption);
    }

    if (sortingOption !== WaypointListSortingOption.Closest) {
      // Clear our position tracking when not using Closest
      debouncedPositionRef.current = { latitude: 0, longitude: 0 };
      prevPositionRef.current = { latitude: 0, longitude: 0 };
      lastSortTimeRef.current = 0;
      toast.info('Position tracking reset - switched away from Closest sorting');
    } else {
      toast.info('Switched to Closest sorting - position tracking active');
    }
  }, [sortingOption]);

  const returnValue = useMemo(() => {
    return {
      sortedWaypointGroups: sortedWaypointGroups || [],
      currentSortingOption: sortingOption,
      setSortedWaypointGroups,
      isFiltersSelected,
    };
  }, [sortedWaypointGroups, sortingOption, isFiltersSelected]);

  return returnValue;
}
