import { useLiveQuery } from 'dexie-react-hooks';
import { UnloadRequest, UnloadRequestType, unloadsRequestsDb } from '../database';
import { useProcessor } from './useProcessor';
import { useOfflineStatus } from '../contexts/offlineStatusContext';
import { useCreateWaypointUnloadMutation, useDeleteWaypointUnloadMutation, useUpdateWaypointUnloadMutation } from '@/features/route';
import { WaypointUnloadDeleteDto } from '@/services/discharge-waypoints.service';
import { useAppDispatch } from '@/store/types';
import { setCreatedWaypointResult } from '@/features/unloading';
import { WaypointUnloadCreateDto, WaypointUnloadUpdateDto } from '@ekt-group/general-purpose-api-interfaces';
import { AxiosError } from 'axios';
import { useEffect } from 'react';

export function useUnloadsRequestsProcessor() {
  const requests = useLiveQuery(() => unloadsRequestsDb.requests.toArray());
  const isOffline = useOfflineStatus();

  const [createWaypointUnload] = useCreateWaypointUnloadMutation();
  const [updateWaypointUnload] = useUpdateWaypointUnloadMutation();
  const [deleteWaypointUnload] = useDeleteWaypointUnloadMutation();
  const dispatch = useAppDispatch();

  // Fix duplicate editing create requests by keeping the newest one
  // the idea is that there can be never two "isEditing" create requests
  useEffect(() => {
    const fixDuplicateEditingCreateRequests = async () => {
      if (!requests) {
        return;
      }

      const editingCreateRequests = requests.filter((req) => req.isEditing && req.type === UnloadRequestType.Create);

      if (editingCreateRequests.length > 1) {
        const sortedRequests = [...editingCreateRequests].sort((a, b) => (b.timestamp || 0) - (a.timestamp || 0));
        const [newestRequest, ...duplicatesToDelete] = sortedRequests;

        for (const request of duplicatesToDelete) {
          if (request.id) {
            await unloadsRequestsDb.requests.delete(request.id);
          }
        }
      }
    };

    fixDuplicateEditingCreateRequests();
  }, [requests]);

  const processRequest = async ({ type, body, isEditing, timestamp, id }: UnloadRequest) => {
    if (isOffline || isEditing) {
      return;
    }

    let mutation;

    switch (type) {
      case UnloadRequestType.Create: {
        mutation = createWaypointUnload(body as WaypointUnloadCreateDto);
        break;
      }
      case UnloadRequestType.Update: {
        mutation = updateWaypointUnload(body as WaypointUnloadUpdateDto & { id: number });
        break;
      }
      case UnloadRequestType.Delete: {
        mutation = deleteWaypointUnload(body as WaypointUnloadDeleteDto);
        break;
      }
      default: {
        throw new Error(`Unknown request type: ${type}`);
      }
    }

    try {
      await mutation.unwrap();
    } catch (error: any) {
      if (error?.code === 'ERR_BAD_REQUEST' && error?.message?.includes('404')) {
        // If the resource is already deleted on the server (404), we can safely delete the local entry
        await deleteRequest(id);
      }
      throw error;
    }
  };

  const deleteRequest = async (requestId: number) => {
    const request = requests.find((r) => r.id === requestId);
    if (request?.isEditing) {
      return;
    }
    return unloadsRequestsDb.requests.delete(requestId);
  };

  useProcessor<UnloadRequest>({
    dbItems: requests,
    processDbItem: processRequest,
    deleteDbItem: deleteRequest,
    rollbarErrorMessage: 'Processing unload request failed',
  });

  return { processRequest, deleteRequest };
}
