import Button from '@/components/elements/Button';
import ModalWrapper from '@/components/modals/ModalWrapper';
import { unloadsRequestsDb } from '@/database';
import { selectCreatedWaypointResult } from '@/features/unloading';
import { WaypointUnload } from '@ekt-group/general-purpose-api-interfaces';
import { useLiveQuery } from 'dexie-react-hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

interface UnloadFormConfirmCloseModalProps {
  isVisible: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  currentWeight?: number;
  currentGrossWeight?: number;
  currentNetWeight?: number;
}

export const UnloadFormConfirmCloseModal = ({
  isVisible,
  onConfirm,
  onCancel,
  currentWeight,
  currentGrossWeight,
  currentNetWeight,
}: UnloadFormConfirmCloseModalProps) => {
  const { t } = useTranslation();
  const createdWaypointResult = useSelector(selectCreatedWaypointResult);
  const editingUnloadRequestBody =
    useLiveQuery(() => unloadsRequestsDb.requests.toArray())?.find((request) => request.isEditing)?.body || null;

  const displayInfo: Partial<WaypointUnload> = createdWaypointResult || editingUnloadRequestBody;

  const grossWeight = currentGrossWeight !== undefined ? currentGrossWeight : displayInfo?.gross;
  const netWeight = currentNetWeight !== undefined ? currentNetWeight : displayInfo?.net;
  const weight = currentWeight !== undefined ? currentWeight : displayInfo?.value;

  return (
    <ModalWrapper isVisible={isVisible} className="w-2/3 p-5 md:w-2/5 h-fit">
      <div className="flex flex-col gap-y-5">
        <div className="text-2xl font-extrabold">
          {t('entryDeleteLabel', {
            ns: 'unloadingPage',
          })}
          :
        </div>
        <div className="text-xl">
          <div>
            <span className="font-extrabold"></span>
            {t('grossWeight', {
              ns: 'unloadingPage',
            })}
            : {grossWeight}
          </div>
          <div>
            <span className="font-extrabold"></span>
            {t('netWeight', {
              ns: 'unloadingPage',
            })}
            : {netWeight}
          </div>
          <div>
            <span className="font-extrabold"></span>
            {t('weight', {
              ns: 'unloadingPage',
            })}
            : {weight}
          </div>
        </div>
        <div className="flex flex-col items-center justify-center w-full gap-5 md:flex-row">
          <Button
            className="flex-1 mr-2"
            size="lg"
            onClick={onCancel}
            text={t('cancel', {
              ns: 'common',
            })}
            color="disabled"
          />
          <Button
            className="flex-1 mr-2"
            size="lg"
            onClick={onConfirm}
            text={t('confirm', {
              ns: 'common',
            })}
            color="primary"
          />
        </div>
      </div>
    </ModalWrapper>
  );
};
