import React, { useCallback } from 'react';
import { getReadableDateTime } from '@/helpers/dateHelper';
import Input from '@/components/elements/Input';
import { WaypointUnload } from '@ekt-group/general-purpose-api-interfaces';
import { debounce } from 'lodash';
import { useUnloadWeightsForm, WeightForm } from '@/features/route';
import { useTranslation } from 'react-i18next';
import { useUnloadFormRequests } from '@/features/route';

interface UnloadPointsListItemUnloadProps {
  unload: Partial<WaypointUnload>;
  allowGrossNetInput: boolean;
}

const UnloadPointsListItemUnload = ({ unload, allowGrossNetInput }: UnloadPointsListItemUnloadProps) => {
  const { value, gross, net, issued } = unload;
  const { weight, grossWeight, netWeight, isInvalidWeight, setValue } = useUnloadWeightsForm(
    {
      initialWeight: +value,
      initialGrossWeight: +gross,
      initialNetWeight: +net,
    },
    allowGrossNetInput,
  );
  const { t } = useTranslation('unloadingPage');

  const { updateUnload } = useUnloadFormRequests();

  const debouncedUpdateUnload = useCallback(
    debounce(async (weight, grossWeight, netWeight) => {
      updateUnload(
        {
          weight,
          grossWeight,
          netWeight,
        },
        unload,
      );
    }, 1000),
    [],
  );

  const handleInputsChange = (key: keyof WeightForm, value: number) => {
    let updatedWeight = weight;
    let updatedGrossWeight = grossWeight;
    let updatedNetWeight = netWeight;

    switch (key) {
      case 'grossWeight': {
        setValue('grossWeight', value);
        updatedGrossWeight = value;
        break;
      }
      case 'netWeight': {
        setValue('netWeight', value);
        updatedNetWeight = value;
        break;
      }
      case 'weight': {
        setValue('weight', value);
        updatedWeight = value;
        break;
      }
    }

    if (allowGrossNetInput) {
      setValue('weight', updatedGrossWeight - updatedNetWeight);
      updatedWeight = updatedGrossWeight - updatedNetWeight;
    }

    if (!isInvalidWeight(updatedWeight, updatedGrossWeight, updatedNetWeight)) {
      debouncedUpdateUnload(updatedWeight, updatedGrossWeight, updatedNetWeight);
    }
  };

  return (
    <div className="unloading-page__list-item__unload" key={unload.id}>
      {allowGrossNetInput ? (
        <div className="unloading-page__list-item__unload__inputs">
          <span className="unloading-page__list-item__unload__date">{getReadableDateTime(issued, 'datetime')}</span>
          <div className="unloading-page__list-item__unload__inputs-wrapper">
            <Input
              value={grossWeight}
              label={t('grossWeight')}
              inputMode={'decimal'}
              onChange={(value) => handleInputsChange('grossWeight', +value)}
              checkForNaN={true}
            />
            <Input
              value={netWeight}
              label={t('netWeight')}
              inputMode={'decimal'}
              onChange={(value) => handleInputsChange('netWeight', +value)}
              checkForNaN={true}
            />
            <div className="unloading-page__list-item__unload__inputs-weight">
              <span>{t('weight')}</span>
              <span>{weight} kg</span>
            </div>
          </div>
          {isInvalidWeight(weight, grossWeight, netWeight) && (
            <span className="unloading-page__list-item__unload__invalid-weight">{t('netWeightError')}</span>
          )}
        </div>
      ) : (
        <>
          <span className="unloading-page__list-item__unload__date">{getReadableDateTime(issued, 'datetime')}</span>
          <span className="unloading-page__list-item__unload__value">
            <Input value={weight} onChange={(value) => handleInputsChange('weight', +value)} inputMode={'decimal'} checkForNaN={true} /> kg
          </span>
        </>
      )}
    </div>
  );
};

export default UnloadPointsListItemUnload;
