import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectSelectedUnloadPoint, setCreatedWaypointResult, setSelectedUnloadPoint } from '@/features/unloading';
import Input from '@/components/elements/Input';
import Button from '@/components/elements/Button';
import { selectCommonItemMap } from '@/store/common/slice';
import { selectCurrentRouteGroupItem } from '@/features/home';
import { useTranslation } from 'react-i18next';
import { selectCurrentGarbageIds } from '@/store/waypoints/slice';
import { SaveUnloadPayload, UpdateUnloadPayload, useUnloadFormRequests } from '../hooks/useUnloadFormRequests';
import { useUnloadWeightsForm, WeightForm } from '../hooks';
import { UnloadRequestType, unloadsRequestsDb } from '@/database';
import { debounce } from 'lodash';
import { WaypointUnload, WaypointUnloadCreateDto } from '@ekt-group/general-purpose-api-interfaces';
import { useOfflineStatus } from '@/contexts/offlineStatusContext';
import { UnloadFormConfirmCloseModal } from './UnloadFormConfirmCloseModal';
import { useLiveQuery } from 'dexie-react-hooks';

const DEBOUNCE_PERIOD = 3000;

const UnloadFormSidebar = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('unloadingPage');
  const isOffline = useOfflineStatus();

  //------------------------------------------------------
  // State and Selectors
  //------------------------------------------------------
  const currentRouteGroupItem = useSelector(selectCurrentRouteGroupItem);
  const unloadPoint = useSelector(selectSelectedUnloadPoint);
  const currentGarbageIds = useSelector(selectCurrentGarbageIds);
  const garbageMap = useSelector(selectCommonItemMap('garbage'));

  const possibleGarbageIds = useMemo<(string | number)[]>(
    () => currentRouteGroupItem?.routeGroup?.garbageIds || currentGarbageIds || [],
    [currentRouteGroupItem, currentGarbageIds],
  );

  const [selectedGarbageId, setSelectedGarbageId] = useState<number | null>(Number(possibleGarbageIds?.[0]) || null);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [hasModifiedForm, setHasModifiedForm] = useState(false);

  //------------------------------------------------------
  // Hooks and Services
  //------------------------------------------------------
  const {
    saveUnload,
    setNavigationToUnloadPoint,
    isPending,
    createOrUpdateUnloadIndexDb,
    updateUnload,
    createdWaypointResult,
    deleteUnload,
  } = useUnloadFormRequests(unloadPoint);

  const editingUnloadRequest = useLiveQuery(() => unloadsRequestsDb.requests.toArray())?.find((request) => request.isEditing) || null;
  const editingUnloadRequestBody = editingUnloadRequest?.body as WaypointUnloadCreateDto | null;

  // Only use pendingUnloadItem if there's an actual editing request for the current unload point
  // or a created waypoint result for the current unload point
  const isEditingCurrentUnloadPoint = editingUnloadRequest && editingUnloadRequestBody?.unloadPointId === unloadPoint?.id;
  const isCreatedWaypointForCurrentPoint = createdWaypointResult && createdWaypointResult.unloadPointId === unloadPoint?.id;

  // Only use pendingUnloadItem when appropriate - coming from editingUnloadRequest or a deliberate creation
  const pendingUnloadItem =
    (isEditingCurrentUnloadPoint && editingUnloadRequestBody) || (isCreatedWaypointForCurrentPoint && createdWaypointResult);

  //------------------------------------------------------
  // Form State Management
  //------------------------------------------------------
  const { weight, grossWeight, netWeight, isInvalidWeight, setValue } = useUnloadWeightsForm(
    {
      initialWeight: pendingUnloadItem?.value || 0,
      initialGrossWeight: pendingUnloadItem?.gross || 0,
      initialNetWeight: pendingUnloadItem?.net || 0,
    },
    unloadPoint?.allowGrossNetInput,
  );

  const isSaveDisabled = useMemo(() => {
    return isInvalidWeight(weight, grossWeight, netWeight) || !selectedGarbageId || isPending;
  }, [grossWeight, isInvalidWeight, netWeight, selectedGarbageId, weight, isPending]);

  const hasUnsavedChanges = useMemo(() => {
    return hasModifiedForm && !isInvalidWeight(weight, grossWeight, netWeight) && selectedGarbageId;
  }, [hasModifiedForm, isInvalidWeight, weight, grossWeight, netWeight, selectedGarbageId]);

  //------------------------------------------------------
  // Navigation and Cleanup
  //------------------------------------------------------
  const clearUnloadStore = useCallback(() => {
    dispatch(setSelectedUnloadPoint(null));
    dispatch(setCreatedWaypointResult(null));
  }, [dispatch]);

  const onCloseSidebarClick = useCallback(() => {
    if (createdWaypointResult || editingUnloadRequest) {
      setIsConfirmModalOpen(true);
      return;
    }
    clearUnloadStore();
  }, [clearUnloadStore, createdWaypointResult, editingUnloadRequest]);

  const handleUserConfirmedLeave = useCallback(async () => {
    // editingUnloadRequest = it was created offline and is an entry in the indexedDb
    if (editingUnloadRequest) {
      await unloadsRequestsDb.requests.delete(editingUnloadRequest.id);
    }

    // createdWaypointResult = it was created online and is an entry in the server
    if (createdWaypointResult) {
      await deleteUnload(createdWaypointResult);
    }

    // Reset form state
    setHasModifiedForm(false);
    clearUnloadStore();
  }, [editingUnloadRequest, createdWaypointResult, deleteUnload, clearUnloadStore]);

  const handleDeleteConfirmButtonClicked = () => {
    handleUserConfirmedLeave();
    setIsConfirmModalOpen(false);
  };

  //------------------------------------------------------
  // Page Unload Handling
  //------------------------------------------------------
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (hasUnsavedChanges) {
        event.preventDefault();
        event.returnValue = t('unsavedChangesWarning', { defaultValue: 'You have unsaved changes. Are you sure you want to leave?' });
        return event.returnValue;
      }
    };

    const handleUnload = () => {
      if (hasUnsavedChanges && editingUnloadRequest) {
        try {
          localStorage.setItem('deleteEditingRequestId', String(editingUnloadRequest.id));
        } catch (e) {
          // Ignore errors with localStorage
        }
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('pagehide', handleUnload);
    window.addEventListener('unload', handleUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('pagehide', handleUnload);
      window.removeEventListener('unload', handleUnload);
    };
  }, [hasUnsavedChanges, t, editingUnloadRequest]);

  // Check for and process any pending deletions from previous unload
  useEffect(() => {
    const checkForPendingDeletions = async () => {
      try {
        const deleteRequestId = localStorage.getItem('deleteEditingRequestId');
        if (deleteRequestId) {
          localStorage.removeItem('deleteEditingRequestId');

          const requestId = parseInt(deleteRequestId, 10);
          if (!isNaN(requestId)) {
            await unloadsRequestsDb.requests.delete(requestId);
          }
        }
      } catch (e) {
        // Ignore errors with localStorage or database operations
      }
    };

    checkForPendingDeletions();
  }, []);

  // Initialize navigation
  useEffect(() => {
    setNavigationToUnloadPoint();
  }, []);

  //------------------------------------------------------
  // Debounced Save/Update Handling
  //------------------------------------------------------
  // Create references to store debounced functions so we can cancel them
  const debouncedSaveUnloadRef = useRef(null);
  const debouncedUpdateUnloadRef = useRef(null);

  const sendSaveUnloadRequestAndUpdateServerState = useCallback(
    async (saveUnloadPayload: SaveUnloadPayload) => {
      const result = await saveUnload(saveUnloadPayload);
      dispatch(setCreatedWaypointResult(result));
    },
    [saveUnload, dispatch],
  );

  const sendUpdateUnloadRequestAndUpdateServerState = useCallback(
    async (updateUnloadPayload: UpdateUnloadPayload, unload: Partial<WaypointUnload>) => {
      await updateUnload(updateUnloadPayload, unload);
      dispatch(setCreatedWaypointResult({ ...unload, ...updateUnloadPayload }));
    },
    [updateUnload, dispatch],
  );

  const debouncedSaveUnload = useCallback(
    debounce(async (saveUnloadPayload: SaveUnloadPayload) => {
      sendSaveUnloadRequestAndUpdateServerState(saveUnloadPayload);
    }, DEBOUNCE_PERIOD),
    [],
  );

  useEffect(() => {
    debouncedSaveUnloadRef.current = debouncedSaveUnload;
  }, [debouncedSaveUnload]);

  const debouncedUpdateUnload = useCallback(
    debounce(async (updateUnloadPayload: UpdateUnloadPayload, unload) => {
      sendUpdateUnloadRequestAndUpdateServerState(updateUnloadPayload, unload);
    }, DEBOUNCE_PERIOD),
    [],
  );

  useEffect(() => {
    debouncedUpdateUnloadRef.current = debouncedUpdateUnload;
  }, [debouncedUpdateUnload]);

  const cancelDebouncedOperations = useCallback(() => {
    if (debouncedSaveUnloadRef.current) {
      debouncedSaveUnloadRef.current.cancel();
    }
    if (debouncedUpdateUnloadRef.current) {
      debouncedUpdateUnloadRef.current.cancel();
    }
  }, []);

  //------------------------------------------------------
  // Save/Update Handlers
  //------------------------------------------------------
  const handleOnlineSaveOrUpdateUnload = async (saveUnloadPayload: SaveUnloadPayload, isDebounce: boolean, afterSaving?: () => void) => {
    const isValidCreatedWaypointResult = createdWaypointResult && createdWaypointResult.unloadPointId === unloadPoint?.id;

    // no "createWaypointResult" means no POST request to the server has been made
    // and user has been interacting with the indexedDb
    if (!isValidCreatedWaypointResult) {
      isDebounce ? debouncedSaveUnload(saveUnloadPayload) : saveUnload(saveUnloadPayload);
      afterSaving && afterSaving();
      return;
    }

    // without garbageId
    const updateUnloadPayload: UpdateUnloadPayload = {
      weight: saveUnloadPayload.weight,
      grossWeight: saveUnloadPayload.grossWeight,
      netWeight: saveUnloadPayload.netWeight,
    };

    isDebounce
      ? debouncedUpdateUnload(updateUnloadPayload, createdWaypointResult)
      : updateUnload(updateUnloadPayload, createdWaypointResult);

    afterSaving && afterSaving();
  };

  // isDebounce usually means, is not from "Save" button
  const handleOfflineSaveOrUpdateUnload = async (saveUnloadPayload: SaveUnloadPayload, isDebounce: boolean, afterSaving?: () => void) => {
    const isValidCreatedWaypointResult = createdWaypointResult && createdWaypointResult.unloadPointId === unloadPoint?.id;

    // no "createWaypointResult" means no POST request to the server has been made
    // and user has been interacting with the indexedDb
    if (!isValidCreatedWaypointResult) {
      const payloadWithEditing = {
        ...saveUnloadPayload,
        isEditing: isDebounce && unloadPoint?.id !== undefined, // Only mark as editing for auto-saves and when we have a valid unload point
      };
      await createOrUpdateUnloadIndexDb(payloadWithEditing);
      afterSaving && afterSaving();
      return;
    }

    // without garbageId
    const updateUnloadPayload: UpdateUnloadPayload = {
      weight: saveUnloadPayload.weight,
      grossWeight: saveUnloadPayload.grossWeight,
      netWeight: saveUnloadPayload.netWeight,
    };

    isDebounce
      ? debouncedUpdateUnload(updateUnloadPayload, createdWaypointResult)
      : updateUnload(updateUnloadPayload, createdWaypointResult);
    afterSaving && afterSaving();
  };

  const handleSaveUnload = async ({
    weight,
    grossWeight,
    netWeight,
    isFromSaveButton = false,
  }: {
    weight: number;
    grossWeight: number;
    netWeight: number;
    isFromSaveButton?: boolean;
  }) => {
    // Prevent duplicate save operations
    if (isPending) {
      return;
    }

    // If this is a manual save, cancel any pending auto-saves
    if (isFromSaveButton) {
      cancelDebouncedOperations();
    }

    if (isInvalidWeight(weight, grossWeight, netWeight)) {
      return;
    }

    const saveUnloadPayload: SaveUnloadPayload = {
      weight,
      grossWeight,
      netWeight,
      garbageId: selectedGarbageId,

      isEditing: !isFromSaveButton,
    };

    // If we have a created waypoint result, we should update it, instead of creating a new one
    if (createdWaypointResult && createdWaypointResult.unloadPointId === unloadPoint?.id) {
      if (isFromSaveButton) {
        dispatch(setCreatedWaypointResult(null));
        await handleOnlineSaveOrUpdateUnload(saveUnloadPayload, false, () => {
          clearUnloadStore();
          setHasModifiedForm(false);
        });
        return;
      }

      await handleOnlineSaveOrUpdateUnload(saveUnloadPayload, true);
      return;
    }

    // Online, but if there's an entry ("editingUnloadRequest") in the indexedDb, we do not go into this
    // and instead go into the offline flow below
    if (!isOffline && !editingUnloadRequest) {
      if (isFromSaveButton) {
        dispatch(setCreatedWaypointResult(null));
        await handleOnlineSaveOrUpdateUnload(saveUnloadPayload, false, () => {
          clearUnloadStore();
          setHasModifiedForm(false);
        });
        return;
      }

      await handleOnlineSaveOrUpdateUnload(saveUnloadPayload, true);
      return;
    }

    // Offline flow
    if (isFromSaveButton) {
      if (editingUnloadRequest) {
        // Simply remove the isEditing flag and let the processor handle the creation later
        await unloadsRequestsDb.requests.update(editingUnloadRequest?.id, { isEditing: false });
        dispatch(setCreatedWaypointResult(null));
        clearUnloadStore();
        setHasModifiedForm(false);
        return;
      }

      // If there's no editing request, create a new one that's ready for processing
      const saveUnloadPayloadWithoutEditing = {
        ...saveUnloadPayload,
        isEditing: false, // Ensure it's ready for processing
      };
      dispatch(setCreatedWaypointResult(null));
      await handleOfflineSaveOrUpdateUnload(saveUnloadPayloadWithoutEditing, false, () => {
        clearUnloadStore();
        setHasModifiedForm(false);
      });
      return;
    }

    // Auto-save in offline mode
    handleOfflineSaveOrUpdateUnload(saveUnloadPayload, true);
  };

  //------------------------------------------------------
  // Input Handling
  //------------------------------------------------------
  const handleInputsChange = (key: keyof WeightForm, value: number) => {
    let updatedWeight = weight;
    let updatedGrossWeight = grossWeight;
    let updatedNetWeight = netWeight;

    switch (key) {
      case 'weight': {
        setValue('weight', value);
        updatedWeight = value;
        break;
      }
      case 'grossWeight': {
        setValue('grossWeight', value);
        updatedGrossWeight = value;
        break;
      }
      case 'netWeight': {
        setValue('netWeight', value);
        updatedNetWeight = value;
        break;
      }
      default: {
        setValue('weight', value);
        updatedWeight = value;
      }
    }

    if (unloadPoint?.allowGrossNetInput) {
      setValue('weight', updatedGrossWeight - updatedNetWeight);
    }

    setHasModifiedForm(true);

    if (selectedGarbageId && !isInvalidWeight(updatedWeight, updatedGrossWeight, updatedNetWeight) && !isPending) {
      // auto-saving - directly call handleSaveUnload without additional debouncing
      handleSaveUnload({
        weight: unloadPoint?.allowGrossNetInput ? updatedGrossWeight - updatedNetWeight : updatedWeight,
        grossWeight: updatedGrossWeight,
        netWeight: updatedNetWeight,
        isFromSaveButton: false,
      });
    }
  };

  //------------------------------------------------------
  // Render
  //------------------------------------------------------
  return (
    <div className={`unloading-form-sidebar ${unloadPoint?.id ? 'active' : ''}`}>
      <UnloadFormConfirmCloseModal
        isVisible={isConfirmModalOpen}
        onConfirm={handleDeleteConfirmButtonClicked}
        onCancel={() => {
          setIsConfirmModalOpen(false);
        }}
        currentWeight={weight}
        currentGrossWeight={grossWeight}
        currentNetWeight={netWeight}
      />
      <div className="unloading-form-sidebar__inputs">
        {unloadPoint?.allowGrossNetInput ? (
          <>
            <div>
              <span>{t('grossWeight')}</span>
              <div>
                <Input
                  value={grossWeight.toString() || '0'}
                  placeholder="0"
                  inputMode={'decimal'}
                  suffix={'kg'}
                  onChange={(value) => {
                    const numValue = parseFloat(value) || 0;
                    handleInputsChange('grossWeight', numValue);
                  }}
                  checkForNaN={true}
                />
              </div>
            </div>
            <div>
              <span>{t('netWeight')}</span>
              <Input
                value={netWeight.toString() || '0'}
                placeholder="0"
                inputMode={'decimal'}
                suffix={'kg'}
                onChange={(value) => {
                  const numValue = parseFloat(value) || 0;
                  handleInputsChange('netWeight', numValue);
                }}
                checkForNaN={true}
              />
            </div>
            <div className="unloading-page__list-item__unload__inputs-weight">
              <span>{t('weight')}</span>
              <span>{weight}</span>
            </div>
          </>
        ) : (
          <div>
            {t('weight')}:{' '}
            <Input
              value={weight.toString() || '0'}
              inputMode={'decimal'}
              suffix={'kg'}
              placeholder={'0'}
              onChange={(value) => {
                const numValue = parseFloat(value) || 0;
                handleInputsChange('weight', numValue);
              }}
              checkForNaN={true}
            />
          </div>
        )}
      </div>
      {isInvalidWeight(weight, grossWeight, netWeight) && (
        <div className="unloading-form-sidebar__error">
          <span>{t('netWeightError')}</span>
        </div>
      )}
      {createdWaypointResult && createdWaypointResult.unloadPointId === unloadPoint?.id && (
        <div className="flex items-center gap-1 font-medium text-green-600 dark:text-green-400 text-sm">
          <div className="bg-green-500 dark:bg-green-400 rounded-full w-3 h-3"></div>
          <span>{t('draftSaved', { defaultValue: 'Draft saved' })}</span>
        </div>
      )}
      <div className="unloading-form-sidebar__garbage">
        <span>{t('selectGarbage')}</span>
        <div className="unloading-form-sidebar__garbage__list">
          {garbageMap &&
            possibleGarbageIds.map((garbageId) => (
              <div
                key={garbageId}
                className={`unloading-form-sidebar__garbage__list-item ${Number(garbageId) === selectedGarbageId ? 'active' : ''}`}
                onClick={() => setSelectedGarbageId(Number(garbageId))}
              >
                {garbageMap[garbageId]?.name}
              </div>
            ))}
        </div>
      </div>

      <div className="unloading-form-sidebar__actions">
        <Button text={t('close', { ns: 'common' })} color={'disabled'} size="md" onClick={onCloseSidebarClick} wide />
        <Button
          text={t('save', { ns: 'common' })}
          color={'success'}
          disabled={isSaveDisabled}
          pending={isPending}
          size="md"
          onClick={() => {
            cancelDebouncedOperations();
            handleSaveUnload({
              weight,
              grossWeight,
              netWeight,
              isFromSaveButton: true,
            });
          }}
          wide
        />
      </div>
    </div>
  );
};

export default React.memo(UnloadFormSidebar);
